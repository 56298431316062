import "./DesktopOfferList.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reserveSection } from "../../Api/reservation";
import SearchInput from "../SearchInput/SearchInput";
import { useToastError } from "../../hooks/useToast";
import { IOfferedSubject, IReserveError, ISelectedSection } from "../../types";
import { normalizeText } from "../../utils";
import { selectStudent, setStudentInicializado } from "../../reduxSlices/studentSlice";
import {
  addSelectedOfferedSubject,
  removeSelectedOfferedSubject,
  selectFilterTagsList,
  selectInscribedSubjects,
  selectOfferedSubjects,
  selectPinnedSubjectsList,
  selectSearchSubjectsValue,
  selectSelectedFilterTagsList,
  selectSubjectsQuotas,
  setLoadingRequestInTransit,
  setSelectedFilterTagsList,
} from "../../reduxSlices/subjectsSlice";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import DesktopTab from "../DesktopTab/DesktopTab";
import { setInTransitInscriptionRequest, setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { NavOptions } from "../NavOptions/NavOptions";
import DesktopSubjectItem from "../DesktopSubjectItem/DesktopSubjectItem";
import OfferSubjectsVirtualizedList from "../OfferSubjectsVirtualizedList/OfferSubjectsVirtualizedList";
import { VariableSizeList as List } from "react-window";

const filterSubjects = (subjects: IOfferedSubject[], searchTerm: string): IOfferedSubject[] => {
  const filteredSubjects = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        const matchesTitle = normalizeText(section.section).includes(normalizeText(searchTerm));
        const matchesProf = section.subjectData.subjectsByType.some((subject) => {
          return subject.subjectInfo.some((info) => {
            return info.values.profesores.some((prof) => {
              return normalizeText(prof).includes(normalizeText(searchTerm));
            });
          });
        });
        return matchesTitle || matchesProf;
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);
  return filteredSubjects;
};

const filterSubjectsByTag = (subjects: IOfferedSubject[], filterTagsList: string[]) => {
  if (!filterTagsList.length) {
    return subjects;
  }
  const subjectsByFilter = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        // Verificar que 'tag' esté definido y sea una cadena
        if (typeof section.tag !== "string") {
          return false;
        }
        // Dividir la cadena 'tag' y limpiar los espacios
        const tags = section.tag.split(",").map((item) => item.trim());
        // Verificar si algún elemento de 'tags' está en 'filterTagsList'
        // console.log(tags.some((item) => filterTagsList.includes(item)));
        return tags.some((item) => filterTagsList.includes(item));
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);

  return subjectsByFilter;
};

const DesktopOfferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector(selectStudent);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const selectedFilterTagsListStore = useSelector(selectSelectedFilterTagsList);
  const searchSubjectValue = useSelector(selectSearchSubjectsValue);
  const filterTagsList = useSelector(selectFilterTagsList);

  const [filteredSubjects, setFilteredSubjects] = useState<IOfferedSubject[]>([]);
  const [filtro, setFiltro] = useState("OBLIGATORIO");
  const [subjectsInSearch, setSubjectsInSearch] = useState<IOfferedSubject[]>([]);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const virtualListRef = useRef<List>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPositionVirtualList, setScrollPositionVirtualList] = useState(0);

  const [reserveError, setReserveError] = useState<IReserveError>({});
  const showError = useToastError();

  const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  /* -------------------------------------------------------------------------- */
  /*                             SET OFERTA if USER                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (offeredSubjects && offeredSubjects.length) {
      dispatch(setStudentInicializado(2));
      const result = offeredSubjects.filter((s) => s.tipoMateria === filtro);
      setFilteredSubjects(result);
    } else {
      dispatch(setStudentInicializado(0));
    }
  }, [offeredSubjects, filtro]);

  /* -------------------------------- Busqueda + Filtros---------------------- */
  useEffect(() => {
    if (filteredSubjects && searchSubjectValue.trim()?.length > 0) {
      const newSubjects = filterSubjects(filteredSubjects, searchSubjectValue);
      const newSubjectsWithFilter = filterSubjectsByTag(newSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjectsWithFilter);
    } else if (filteredSubjects) {
      const newSubjects = filterSubjectsByTag(filteredSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjects);
    }
  }, [searchSubjectValue, selectedFilterTagsListStore, filteredSubjects]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleScroll = () => {
    setScrollPosition(Number(scrollTargetRef.current?.scrollTop));
  };

  const handleScrollVirtualList = (data: any) => {
    setScrollPositionVirtualList(Number(data.scrollOffset));
  };

  const handleReserve = async (selectedSection: ISelectedSection) => {
    let reserveResult: any = {};
    try {
      dispatch(setInTransitInscriptionRequest(true));
      if (selectedSection) {
        const results = await reserveSection({
          idC: selectedSection.idC,
          idS: selectedSection.idS,
          idA: selectedSection.idA,
          tim: selectedSection.tim,
          tic: selectedSection.tic,
          idPersona: student.id.toString(),
        });

        reserveResult = results.data;

        if (results.data.responseCode !== 200) {
          dispatch(removeSelectedOfferedSubject(selectedSection.idS));
          setReserveError({
            ...reserveError,
            [selectedSection.idC]: results.data.responseMessage,
          });
          showError(results.data.responseMessage, () =>
            setReserveError((prevState) => {
              const newState = prevState;
              delete newState[selectedSection.idC];
              return newState;
            })
          );
        } else {
          dispatch(addSelectedOfferedSubject(selectedSection.idS));

          await dispatch(setLoadingRequestInTransit(true));
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
      reserveResult = error;
    } finally {
      dispatch(setInTransitInscriptionRequest(false));
    }
    return reserveResult;
  };

  const handleSelectTab = (tipo: string) => {
    if (tipo === "OBLIGATORIO") {
      dispatch(setSelectedFilterTagsList([]));
      dispatch(setPanelFilters(false));
    }
    if (tipo) {
      setFiltro(tipo);
    } else {
      setFiltro("OBLIGATORIO");
    }
  };

  const handleOpenFilter = () => {
    dispatch(setPanelFilters(true));
  };

  const scrollToTop = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollTop = 0;
    }
  };

  const scrollVirtualListToTop = () => {
    if (virtualListRef.current) {
      virtualListRef.current.scrollTo(0);
    }
  };

  const renderPinnedSubjects = (subjects: IOfferedSubject[]) => {
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    return (
      <>
        {subjects.map((s) => {
          const pinnedSections = s.sections.filter(
            (curso) => pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1
          );
          const itemsList = pinnedSections.map((s) => {
            return (
              <DesktopSubjectItem
                idMateria={s.idMateria}
                id={s.id}
                key={s.id}
                subjectId={s.idMateria}
                section={s.section}
                subjectData={s.subjectData}
                quota={subjectsQuotas[s.id]}
                onChange={handleReserve}
                pinned={pinnedSubjectsList.indexOf(s.id) > -1}
                creditos={s.creditos}
                sectionType={s.tipoMateria}
                turno={s.turno}                
              />
            );
          });

          return itemsList;
        })}
      </>
    );
  };

  const countPinnedByType = (subjects: IOfferedSubject[]) => {
    let count = 0;
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    subjects.forEach((s) => {
      s.sections.forEach((curso) => {
        if (pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1) {
          count++;
        }
      });
    });
    return count;
  };

  return (
    <div className="desktop-offer-list-container">
      {filteredSubjects.length === 0 && student.id && offeredSubjects === null ? (
        <Loader text="Cargando" />
      ) : (
        <>
          <div className="desktop-filters-nav">
            <DesktopTab filtro={filtro} handleSelectTab={handleSelectTab} />
            {/* <NavOptions /> */}
            <div className="search-and-filter">
              <SearchInput />
              {filtro !== "OBLIGATORIO" && filterTagsList && filterTagsList?.length > 0 && (
                <button
                  onClick={handleOpenFilter}
                  className={`filters-btn ${selectedFilterTagsListStore?.length ? "filters-btn-active" : ""}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                    <path
                      d="M14.9814 0H1.01854C0.159386 0 -0.31238 1.04052 0.233792 1.73083L5.9521 8.95821C6.10199 9.14766 6.18396 9.38545 6.18396 9.63088V15.7943C6.18396 16.1287 6.33583 16.4434 6.59355 16.6431L8.07226 17.7887C8.7429 18.3083 9.69589 17.8101 9.69589 16.9399V9.63676C9.69589 9.38776 9.78025 9.14674 9.93414 8.95609L15.7598 1.73882C16.3155 1.05041 15.8453 0 14.9814 0Z"
                      fill={`${selectedFilterTagsListStore?.length ? "white" : "#424242"}`}
                    />
                  </svg>
                  {/* <FilterIcon color={`${selectedFilterTagsListStore?.length ? "white" : "#424242"}`} /> */}
                </button>
              )}
              <NavOptions />
            </div>
          </div>
          <div onScroll={handleScroll} ref={scrollTargetRef} className="desktop-filters-listing">
            {pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0 && (
              <div className="desktop-title-2 pinned-title-2">
                <div className="desktop-title-2-first-col">
                  <h2 className="desktop-title-2-text">
                    Materias Fijadas {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}
                  </h2>
                  <span className="list-subjects-counter">{countPinnedByType(filteredSubjects)}</span>
                </div>

                {scrollPosition > 100 && (
                  <SlIcon onClick={scrollToTop} className="go-to-top" name="arrow-bar-up"></SlIcon>
                )}
              </div>
            )}

            {pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0 && (
              <div className="desktop-pinned-subject-list">{renderPinnedSubjects(filteredSubjects)}</div>
            )}

            <div
              className={`desktop-title-2 ${
                pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0
                  ? "offered-title-sub-2"
                  : "offered-title-2"
              }`}
            >
              <div className="desktop-title-2-first-col">
                <h2 className="desktop-title-2-text">
                  Materias {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}
                </h2>
              </div>
              {scrollPositionVirtualList > 100 && (
                <SlIcon onClick={scrollVirtualListToTop} className="go-to-top" name="arrow-bar-up"></SlIcon>
              )}
            </div>
            <div className="desktop-offered-subject-list">
              <OfferSubjectsVirtualizedList
                subjects={subjectsInSearch}
                virtualListRef={virtualListRef}
                handleScroll={handleScrollVirtualList}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};


export default DesktopOfferList;
