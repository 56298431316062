import { FaUser } from "react-icons/fa6";
import { IOfferedSubjectData, IQuota } from "../../types";
import { getQuotaColor } from "../../utils";

interface Props {
  subjectData: IOfferedSubjectData;
  qty: number;
  quota: IQuota;
  idMateria: number;
  availability: number;
}
export default function QuotaCountIcon({ subjectData, qty, idMateria, quota, availability }: Props) {
  
  const showCupoInfo =
    process.env.REACT_APP_ENV === "Test" ||
    process.env.REACT_APP_ENV === "Localhost" ||
    process.env.REACT_APP_ENV === "Staging" ||
    process.env.REACT_APP_ENV === "Dev";
  
  return (
    <>
      <FaUser
        title={
          subjectData.reserveData.tic && subjectData.reserveData.tim && showCupoInfo
            ? "Cupo: " +
              String(subjectData.reserveData.tic + " " + subjectData.reserveData.tim) +
              "   |   Cuota: " +
              String(qty || "-") +
              `   |   idCurso:  ${subjectData.reserveData.idS}` +
              `   |   idMateria:  ${idMateria}` +
              `   |   idCupo:  ${subjectData.reserveData.idC}`
            : ""
        }
        style={{ color: quota ? getQuotaColor(availability) : "black" }}
        className={`desktop-icon-user-quota ${showCupoInfo ? "desktop-icon-user-quota-hover" : ""}`}
      />
    </>
  );
}
