import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { getCuposCurso, getCuposCursoList } from "../Api/reservation";
import { ISubjectQuotas, ISubjectsSliceState } from "../reduxSlices/subjectsSlice";
import { RootState } from "../app/store";

export const updateCupoCurso = createAsyncThunk<ISubjectQuotas, any, { state: RootState }>(
  "front/updateCupoCurso",
  async (cupoId: number, thunkAPI) => {
    try {
      const response = await getCuposCurso(cupoId);
      return response.data as ISubjectQuotas;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCupoCursoBuilder = (builder: ActionReducerMapBuilder<ISubjectsSliceState>) => {
  builder.addCase(updateCupoCurso.fulfilled, (state, action) => {
    const newQuotas = { ...state.subjectsQuotas, ...action.payload };
    state.subjectsQuotas = newQuotas;
  });
  builder.addCase(updateCupoCurso.rejected, (state, action) => {});
};

export const updateCupoCursosList = createAsyncThunk<ISubjectQuotas, any, { state: RootState }>(
  "front/updateCupoCursosList",
  async (cupoIds: number[], thunkAPI) => {
    try {
      // GET CUPOS POR BATCH
      let newQuotas: ISubjectQuotas = {};
      const response = await getCuposCursoList(cupoIds);
      newQuotas = response.data;
      // GET CUPOS INDIVIDUALES
      /* for (const cupoId of cupoIds) {
        const response = await getCuposCurso(cupoId);
        newQuotas = { ...newQuotas, ...response.data };
      } */
      return newQuotas;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCupoCursosListBuilder = (builder: ActionReducerMapBuilder<ISubjectsSliceState>) => {
  builder.addCase(updateCupoCursosList.fulfilled, (state, action) => {
    const newQuotas = { ...state.subjectsQuotas, ...action.payload };
    console.log("newQuotas:", Object.keys(newQuotas).length);
    state.subjectsQuotas = newQuotas;
  });
  builder.addCase(updateCupoCursosList.rejected, (state, action) => {});
};
