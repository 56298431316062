import React, { useCallback, useEffect, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGridProps, VariableSizeList as List } from "react-window";
import { IOfferedSubject, IQuota, IReserveError, ISelectedSection } from "../../types";
import { useSelector } from "react-redux";
import {
  addSelectedOfferedSubject,
  addSubProcessSectionIds,
  removeSelectedOfferedSubject,
  removeSubProcessSectionIds,
  selectInscribedSubjects,
  selectPinnedSubjectsList,
  selectSearchSubjectsValue,
  selectSelectedFilterTagsList,
  selectSubjectsQuotas,
  setLoadingRequestInTransit,
} from "../../reduxSlices/subjectsSlice";
import "./OfferSubjectsVirtualizedList.css";
import { filterAndOrderSubjects } from "../../utils/subjects";
import DesktopSubjectItem from "../DesktopSubjectItem/DesktopSubjectItem";
import { useAppDispatch } from "../../app/store";
import { selectFlags, selectMobileFeatures, setInTransitInscriptionRequest } from "../../reduxSlices/globalFlagsSlice";
import { changeSection, reserveSection } from "../../Api/reservation";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { showToastError } from "../../hooks/useToast";
import Modal from "../Modal/Modal";

interface Props {
  subjects: IOfferedSubject[];
  virtualListRef: React.MutableRefObject<List<any> | null>;
  handleScroll: (data: any) => void;
}

function OfferSubjectsVirtualizedList({ subjects, virtualListRef, handleScroll }: Props) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const student = useSelector(selectStudent);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const listadoFiltros = useSelector(selectSelectedFilterTagsList);
  const cajaBusqueda = useSelector(selectSearchSubjectsValue);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const { collapseCards, collapseCardsSinCupo } = useSelector(selectFlags);
  const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
  const [reserveError, setReserveError] = useState<IReserveError>({});
  const [selectedSection, setSelectedSection] = useState<ISelectedSection>();

  const subjectsOrdered = filterAndOrderSubjects(subjects, pinnedSubjectsList, inscribedSubsIds);

  useEffect(() => {
    if (reserveError && selectedSection) {
      const keys = Object.keys(reserveError);
      if (keys.length && keys.includes(selectedSection?.idC.toString())) {
        setSelectedSection(undefined);
      }
    }
  }, [reserveError]);


  /* -------------------------------------------------------------------------- */
  /*                              METHODS                                       */
  /* -------------------------------------------------------------------------- */

  const handleRefuse = (idS: number) => {
    setOpen(false);
    setSelectedSection(undefined);
    dispatch(removeSelectedOfferedSubject(idS));
  };

  const handleReserve = async (section?: ISelectedSection) => {
    let result: any = {};
    if (selectedSection) {
      result = await onReserve(selectedSection);
      setOpen(false);
    } else if (section) {
      result = await onReserve(section);
    }
    return result;
  };

  const onReserve = async (selectedSection: ISelectedSection) => {
    let reserveResult: any = {};
    try {
      dispatch(setInTransitInscriptionRequest(true));
      if (selectedSection) {
        const results = await reserveSection({
          idC: selectedSection.idC,
          idS: selectedSection.idS,
          idA: selectedSection.idA,
          tim: selectedSection.tim,
          tic: selectedSection.tic,
          idPersona: student.id.toString(),
        });

        reserveResult = results.data;

        if (results.data.responseCode !== 200) {
          dispatch(removeSelectedOfferedSubject(selectedSection.idS));
          setReserveError({
            ...reserveError,
            [selectedSection.idC]: results.data.responseMessage,
          });
          showToastError(results.data.responseMessage);
          setReserveError((prevState) => {
            const newState = prevState;
            delete newState[selectedSection.idC];
            return newState;
          });
        } else {
          dispatch(addSelectedOfferedSubject(selectedSection.idS));

          await dispatch(setLoadingRequestInTransit(true));
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
      reserveResult = error;
    } finally {
      dispatch(setInTransitInscriptionRequest(false));
    }
    return reserveResult;
  };

  /* -------------------------------------------------------------------------- */
  /*                              RENDER COMPONENTS                             */
  /* -------------------------------------------------------------------------- */
  const Row = useCallback(
    ({ index, style }: any) => {
      const s = subjectsOrdered[index];
      return (
        <div style={style}>
          <DesktopSubjectItem
            idMateria={s.idMateria}
            id={s.id}
            key={s.id}
            subjectId={s.idMateria}
            section={s.section}
            subjectData={s.subjectData}
            quota={subjectsQuotas[s.id]}
            onChange={handleReserve}
            pinned={pinnedSubjectsList.indexOf(s.id) > -1}
            creditos={s.creditos}
            sectionType={s.tipoMateria}
            turno={s.turno}
          />
        </div>
      );
    },
    [subjectsOrdered]
  );

  const handleVirtualCardHeight = (index: number): number => {
    const s = subjectsOrdered[index];
    const quota = subjectsQuotas[s.id];
    const mobileSizeNormal = 230;
    const mobileSizeCollapsed = 150;
    const desktopSizeNormal = 200;
    const desktopSizeCollapsed = 80;

    if ((collapseCardsSinCupo && quota && quota.availability <= 0) || collapseCards) {
      if (mobileFeatures) {
        return mobileSizeCollapsed;
      } else {
        return desktopSizeCollapsed;
      }
    } else {
      if (mobileFeatures) {
        return mobileSizeNormal;
      } else {
        return desktopSizeNormal;
      }
    }
  };

  if (subjectsOrdered.length === 0) {
    return (
      <div className="listContainer">
        {(listadoFiltros?.length || cajaBusqueda) && <p>No coinciden materias con la búsqueda o filtros.</p>}
        {!(listadoFiltros?.length || cajaBusqueda) && <p>No hay materias disponibles.</p>}
      </div>
    );
  }

  return (
    <div style={{ flex: "1 1 auto" }}>
      {collapseCards && (
        <AutoSizer>
          {({ height, width }: any) => (
            <List
              ref={(ref) => (virtualListRef.current = ref)}
              onScroll={handleScroll}
              className="List"
              height={height}
              width={width}
              itemCount={subjectsOrdered.length}
              itemSize={(index) => handleVirtualCardHeight(index)}
              itemKey={(index) => subjectsOrdered[index].id}
              layout="vertical"
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      )}
      {collapseCardsSinCupo && (
        <AutoSizer>
          {({ height, width }: any) => (
            <List
              ref={(ref) => (virtualListRef.current = ref)}
              onScroll={handleScroll}
              className="List"
              height={height}
              width={width}
              itemCount={subjectsOrdered.length}
              itemSize={(index) => handleVirtualCardHeight(index)}
              itemKey={(index) => subjectsOrdered[index].id}
              layout="vertical"
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      )}
      {!collapseCardsSinCupo && !collapseCards && (
        <AutoSizer>
          {({ height, width }: any) => (
            <List
              ref={(ref) => (virtualListRef.current = ref)}
              onScroll={handleScroll}
              className="List"
              height={height}
              width={width}
              itemCount={subjectsOrdered.length}
              itemSize={(index) => handleVirtualCardHeight(index)}
              itemKey={(index) => subjectsOrdered[index].id}
              layout="vertical"
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      )}
      {/* {selectedSection && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          message={`¿Desea inscribirse en el curso ${selectedSection.section}?`}
          okButton={{
            label: "Si",
            buttonFn: handleReserve,
          }}
          closeButton={{
            label: "No",
            buttonFn: () => handleRefuse(selectedSection.idS),
          }}
        />
      )} */}
    </div>
  );
}

export default OfferSubjectsVirtualizedList;
