import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import "./TransitionModal.css";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

interface IPropsTransitionModal {
  open: boolean;
  text: string;
}

const TransitionModal = ({ open, text }: IPropsTransitionModal) => {
  return (
    <SlDialog open={open} className="transition-modal" noHeader onSlRequestClose={(e) => e.preventDefault()}>
      {/* TODO: Reemplazar por spinner que nos pasen los clientes */}
      {text === "logoAnimado" ? (
        <DotLottieReact src="/main-animation.lottie" loop autoplay />
      ) : (
        <>
          <SlSpinner
            style={{
              fontSize: "3rem",
              //@ts-ignore
              "--indicator-color": "#00C4B3",
              "--track-color": "#ccebe8",
            }}
          />
          <div className="transition-modal-text">{text}</div>
        </>
      )}
    </SlDialog>
  );
};

export default TransitionModal;
