import "./NavOptions.css";
import { useSelector } from "react-redux";

import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlDropdown from "@shoelace-style/shoelace/dist/react/dropdown";
import SlMenu from "@shoelace-style/shoelace/dist/react/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/react/menu-item";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useAppDispatch } from "../../app/store";
import { selectFlags, setCardsCollapse } from "../../reduxSlices/globalFlagsSlice";

export const NavOptions = () => {
  const dispatch = useAppDispatch();
  const { collapseCards, collapseCardsSinCupo } = useSelector(selectFlags);

  const handleCollapseAll = ()=>{
    if(collapseCards){
      dispatch(setCardsCollapse("none"))
    }else{
      dispatch(setCardsCollapse("all"))
    }
  }

  const handleCollapseSinCupo = ()=>{
    if(collapseCardsSinCupo){
      dispatch(setCardsCollapse("none"))
    }else{
      dispatch(setCardsCollapse("sinCupo"))
    }
  }
  
  return (
    <div className={`nav-options`}>
      <SlDropdown>
        <SlIconButton
          slot="trigger"
          className={`nav-options-btn ${collapseCards || collapseCardsSinCupo ? "nav-options-active" : ""}`}
          name="view-list"
          label="Menu"
        ></SlIconButton>
        <SlMenu>
          <SlMenuItem value="collapse-all" onClick={handleCollapseAll} className="nav-option-item">
            <span className="menu-item">Colapsar tarjetas</span>
            {collapseCards && (
              <SlIcon slot="suffix" name="check-lg" style={{ marginLeft: "10px", color: "#00998C" }}></SlIcon>
            )}
          </SlMenuItem>
          <SlMenuItem value="collapse-sincupo" onClick={handleCollapseSinCupo} style={{display: "none"}}>
            <span className="menu-item">Colapsar tarjetas sin cupo</span>
            {collapseCardsSinCupo && (
              <SlIcon slot="suffix" name="check-lg" style={{ marginLeft: "10px", color: "#00998C" }}></SlIcon>
            )}
          </SlMenuItem>
        </SlMenu>
      </SlDropdown>
    </div>
  );
};
