import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {
  ICalendarSubjects,
  IInscribedSubject,
  IOfferedSubject,
  IOfferedSubjectSection,
  IQuota,
} from "../types";
import { updateCupoCursoBuilder, updateCupoCursosListBuilder } from "../reduxAsyncThunks/subjects";

interface ISelectedOfferedSubjects {
  idS: number;
  subjectId: number;
}

export interface ISubjectQuotas {
  [key: number]: IQuota;
}

export interface ISubjectsSliceState {
  inscribedSubjects: IInscribedSubject[] | null;
  offeredSubjects: IOfferedSubject[] | null;
  selectedOfferedSubjects: ISelectedOfferedSubjects[];
  loadingRequestInTransit: boolean;
  subjectsQuotas: ISubjectQuotas;
  pinnedSubjectsList: number[];
  calendarSubjectsList: ICalendarSubjects[];
  calendarSubjectsDemoCount: number;
  detailSubjectOffered: IOfferedSubjectSection | null;
  detailSubjectInscribed: IInscribedSubject | null;
  filterTagsList: string[] | null;
  selectedFilterTagsList: string[] | null;
  searchSubjectsValue: string;
  unsubProcessSectionIds: number[];
  subcriptionProcessSectionIds: number[];
  failedSubcriptionProcessIds: number[];
}

const initialState: ISubjectsSliceState = {
  inscribedSubjects: null,
  offeredSubjects: null,
  selectedOfferedSubjects: [],
  loadingRequestInTransit: false,
  subjectsQuotas: {},
  pinnedSubjectsList: [],
  calendarSubjectsList: [],
  calendarSubjectsDemoCount: 0,
  detailSubjectOffered: null,
  detailSubjectInscribed: null,
  filterTagsList: null,
  selectedFilterTagsList: null,
  searchSubjectsValue: "",
  unsubProcessSectionIds: [],
  subcriptionProcessSectionIds: [],
  failedSubcriptionProcessIds: [],
};

export const subjectsSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    setInscribedSubjects: (state, action) => {
      state.inscribedSubjects = action.payload;
    },
    setOfferedSubjects: (state, action) => {
      state.offeredSubjects = action.payload;
    },
    addInscribedSubject: (state, action) => {
      if (state.inscribedSubjects) {
        state.inscribedSubjects = [...state.inscribedSubjects, action.payload];
      }
    },
    removeInscribedSubject: (state, action) => {
      if (state.inscribedSubjects) {
        const newInscribedSubjects = state.inscribedSubjects.filter((i) => i.sections[0].idCurso !== action.payload);
        state.inscribedSubjects = newInscribedSubjects;
      }
    },
    addSelectedOfferedSubject: (state, action) => {
      state.selectedOfferedSubjects = [...state.selectedOfferedSubjects, action.payload];
    },
    removeSelectedOfferedSubject: (state, action) => {
      const newSelectedOfferedSubjects = state.selectedOfferedSubjects.filter((i) => i.idS !== action.payload);
      state.selectedOfferedSubjects = newSelectedOfferedSubjects;
    },
    clearSelectedOfferedSubject: (state) => {
      state.selectedOfferedSubjects = [];
    },
    setLoadingRequestInTransit: (state, { payload }) => {
      state.loadingRequestInTransit = payload;
    },
    setSubjectsQuota: (state, action) => {
      state.subjectsQuotas = action.payload;
    },
    setPinnedSubjectsList: (state, action) => {
      state.pinnedSubjectsList = action.payload;
    },
    addCalendarSubject: (state, action) => {
      if (state.calendarSubjectsList) {
        state.calendarSubjectsList = [...state.calendarSubjectsList, action.payload];
      }
    },
    removeCalendarSubject: (state, action) => {
      if (state.calendarSubjectsList) {
        const newCalendarSubjects = state.calendarSubjectsList.filter((i) => i.idCurso !== action.payload);
        state.calendarSubjectsList = newCalendarSubjects;
      }
    },
    removeAllCalendarSubject: (state) => {
      state.calendarSubjectsList = [];
    },
    addCalendarSubjectDemoCount: (state) => {
      state.calendarSubjectsDemoCount = state.calendarSubjectsDemoCount + 1;
    },
    subtractCalendarSubjectDemoCount: (state) => {
      if (state.calendarSubjectsDemoCount > 0) {
        state.calendarSubjectsDemoCount = state.calendarSubjectsDemoCount - 1;
      }
    },
    setDetailSubjectOffered: (state, action) => {
      state.detailSubjectInscribed = null;

      if (action.payload === null) {
        state.detailSubjectOffered = null;
        return;
      }
      const { subjectId, cursoId } = action.payload;
      const materia = state.offeredSubjects?.filter((sub) => sub.id === subjectId);

      if (materia && materia[0]) {
        const curso = materia[0].sections.filter((curso: IOfferedSubjectSection) => curso.id === cursoId);
        if (curso[0]) {
          state.detailSubjectOffered = curso[0];
        }
      }
    },
    setFilterTagsList: (state, action) => {
      state.filterTagsList = action.payload;
    },
    setSelectedFilterTagsList: (state, action) => {
      state.selectedFilterTagsList = action.payload;
    },
    setSearchSubjectsValue: (state, { payload }) => {
      state.searchSubjectsValue = payload;
    },
    setUnsubProcessSectionIds: (state, action) => {
      const index = state.unsubProcessSectionIds.indexOf(action.payload);
      if (index === -1) {
        state.unsubProcessSectionIds.push(action.payload);
      }
    },
    deleteUnsubProcessSectionId: (state, { payload }) => {
      const index = state.unsubProcessSectionIds.indexOf(payload);
      if (index !== -1) {
        state.unsubProcessSectionIds.splice(index, 1);
      }
    },
    clearUnsubProcessSectionIds: (state, action) => {
      state.unsubProcessSectionIds = [];
    },
    addSubProcessSectionIds: (state, action) => {
      state.subcriptionProcessSectionIds.push(action.payload);
    },
    removeSubProcessSectionIds: (state, action) => {
      const index = state.subcriptionProcessSectionIds.indexOf(action.payload);
      if (index !== -1) {
        state.subcriptionProcessSectionIds.splice(index, 1);
      }
    },
    setFailedProcessingSubject: (state, action) => {
      state.failedSubcriptionProcessIds.push(action.payload);
    },
    removeFailedProcessingSubject: (state, action) => {
      const index = state.failedSubcriptionProcessIds.indexOf(action.payload);
      if (index !== -1) {
        state.failedSubcriptionProcessIds.splice(index, 1);
      }
    },
    clearSubProcessSectionIds: (state, action) => {
      state.subcriptionProcessSectionIds = [];
    },
  },
  extraReducers(builder) {
    updateCupoCursoBuilder(builder);
    updateCupoCursosListBuilder(builder);
  },
});

export const selectInscribedSubjects = (state: RootState) => state.subjects.inscribedSubjects;

export const selectOfferedSubjects = (state: RootState) => state.subjects.offeredSubjects;

export const selectSelectedOfferedSubjects = (state: RootState) => state.subjects.selectedOfferedSubjects;

export const selectLoadingRequestInTransit = (state: RootState) => state.subjects.loadingRequestInTransit;

export const selectSubjectsQuotas = (state: RootState) => state.subjects.subjectsQuotas;

export const selectPinnedSubjectsList = (state: RootState) => state.subjects.pinnedSubjectsList;

export const selectCalendarSubjectsList = (state: RootState) => state.subjects.calendarSubjectsList;

export const selectCalendarSubjectsDemoCount = (state: RootState) => state.subjects.calendarSubjectsDemoCount;

export const selectDetailSubjectOffered = (state: RootState) => state.subjects.detailSubjectOffered;

export const selectFilterTagsList = (state: RootState) => state.subjects.filterTagsList;

export const selectSelectedFilterTagsList = (state: RootState) => state.subjects.selectedFilterTagsList;

export const selectSearchSubjectsValue = (state: RootState) => state.subjects.searchSubjectsValue;

export const selectUnsubProcessSectionIds = (state: RootState) => state.subjects.unsubProcessSectionIds;

export const selectSubcriptionProcessSectionIds = (state: RootState) => state.subjects.subcriptionProcessSectionIds;

export const selectFailedSubcriptionIds = (state: RootState) => state.subjects.failedSubcriptionProcessIds;

export const {
  setInscribedSubjects,
  setOfferedSubjects,
  addInscribedSubject,
  removeInscribedSubject,
  addSelectedOfferedSubject,
  removeSelectedOfferedSubject,
  clearSelectedOfferedSubject,
  setLoadingRequestInTransit,
  setSubjectsQuota,
  setPinnedSubjectsList,
  addCalendarSubject,
  removeCalendarSubject,
  addCalendarSubjectDemoCount,
  subtractCalendarSubjectDemoCount,
  setDetailSubjectOffered,
  removeAllCalendarSubject,
  setFilterTagsList,
  setSelectedFilterTagsList,
  setSearchSubjectsValue,
  setUnsubProcessSectionIds,
  clearUnsubProcessSectionIds,
  addSubProcessSectionIds,
  removeSubProcessSectionIds,
  clearSubProcessSectionIds,
  deleteUnsubProcessSectionId,
  setFailedProcessingSubject,
  removeFailedProcessingSubject,
} = subjectsSlice.actions;

export default subjectsSlice.reducer;
