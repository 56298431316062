import "./BannerPrioridad.css";
import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useState } from "react";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export default function BannerPrioridad() {
  const { prioridad, mensajePrioridad, actualizandoPrioridad } = useSelector(selectStudent);
  const [bannerPrioridad, setBannerPrioridad] = useState(true);

  const handleBannerPrioridad = (bool: boolean) => {
    setBannerPrioridad(bool);
  };

  if (!bannerPrioridad) {
    return null;
  }

  return (
    <div className={`desktop-mensaje-prioridad ${prioridad && !actualizandoPrioridad ? "prioridad-activa" : ""}`}>
      <span className="desktop-mensaje-prioridad-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15" fill="none">
          <path
            d="M1.74457 4.12639C2.36431 4.12639 2.8667 4.62879 2.8667 5.24852L2.8667 13.8112C2.8667 14.431 2.36431 14.9333 1.74457 14.9333V14.9333C1.12484 14.9333 0.622446 14.431 0.622446 13.8112L0.622446 5.24852C0.622447 4.62879 1.12484 4.12639 1.74457 4.12639V4.12639ZM1.74457 0.814363C2.35814 0.814363 2.8667 1.31175 2.8667 1.92532V1.92532C2.8667 2.53888 2.35814 3.03627 1.74457 3.03627V3.03627C1.13101 3.03627 0.622447 2.53888 0.622447 1.92532V1.92532C0.622447 1.31175 1.13101 0.814363 1.74457 0.814363V0.814363Z"
            fill="white"
          />
        </svg>
      </span>
      <p>{mensajePrioridad}</p>
      <SlIcon
        className="close-mobile-size min-w-[16px]"
        name="x-lg"
        onClick={() => handleBannerPrioridad(false)}
        style={{ cursor: "pointer" }}
      ></SlIcon>
    </div>
  );
}
