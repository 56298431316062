import React, { useEffect } from "react";
import "./Layout.css";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionModal from "../TransitionModal/TransitionModal";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import { EstadoAlumnoCarrera } from "../../enums/alumnoCarrera.enum";
import ModeHeader from "../ModeHeader/ModeHeader";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import { selectInTransitInRquest, selectPanelFilters, setMobileFeatures } from "../../reduxSlices/globalFlagsSlice";
import PanelDrawer from "../PanelDrawer/PanelDrawer";
import { selectDetailSubjectOffered } from "../../reduxSlices/subjectsSlice";
import PanelFilters from "../PanelFilters/PanelFilters";
import { selectParameter } from "../../reduxSlices/parametersSlice";
import { useAppDispatch } from "../../app/store";



interface IPropsLayout {
  children: React.ReactNode;
  sessionExpired: boolean;
}

const DesktopLayout = ({ children, sessionExpired }: IPropsLayout) => {
  const dispatch = useAppDispatch();
  const student = useSelector(selectStudent);
  const { serverError } = useSelector(selectParameter);
  const detailSubjectOffered = useSelector(selectDetailSubjectOffered);
  const panelFilters = useSelector(selectPanelFilters);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // set ON mobile features
    dispatch(setMobileFeatures(false));

    if (student.bloqueo.activo) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/bloqueo");
    }
    if (serverError) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/error");
    }
    if (sessionExpired) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  }, [serverError, sessionExpired]);

  return (
    <div className="desktop-layout">
      <ModeHeader />
      {/* ------------------------------- HEADER Menu ------------------------------ */}
      {location.pathname === "/" && <DesktopHeader />}
      {/* -------------------------------- CHILDREN -------------------------------- */}
      <div className="desktop-layout-content">{children}</div>
      {/* -------------------------------------------------------------------------- */
      /*                                   MODALES                                  */
      /* -------------------------------------------------------------------------- */}
      {/* ------------------------------ MODAL General ----------------------------- */}
      {(student.id === 0 || student.email === "") && !student.bloqueo.activo && location.pathname === "/" && (
        <TransitionModal open={true} text={"logoAnimado"} />
      )}
      {/* --------------------------- MODAL Inicializando -------------------------- */}
      {student.studentInitState === EstadoAlumnoCarrera.INICIALIZACION_KV_PENDIENTE && (
        <TransitionModal
          open={true}
          text={"Inicializando la información del alumno. Esta operación puede demorar unos minutos."}
        />
      )}
      {/* --------------------------- Overlay espera -------------------------- */}
      {/* {inTransitInscriptionRequet && <ModalOverlay text={""} />} */}
      {/* ----------------------- Panel Drawe Subject Detail ----------------------- */}
      {location.pathname !== "/login" && detailSubjectOffered && <PanelDrawer subject={detailSubjectOffered} />}
      {location.pathname !== "/login" && panelFilters && <PanelFilters />}
    </div>
  );
};

export default DesktopLayout;

// function mapToNewFormat(original: IInscribedSubject): IOfferedSubjectSection {

//   const mappedSubjectInfo = original.sections.map((section, index) => {
//     return {
//       id: 1000 + index, // Asignar ID arbitrario o generar uno
//       title: "Horario",
//       values: {
//         tipoClase: section.subjectData.tipoCursada,
//         dia: section.subjectData.dia,
//         desde: parseInt(section.subjectData.desde, 10),
//         hasta: parseInt(section.subjectData.hasta, 10),
//         rol: "profesor",
//         profesores: [section.subjectData.profesor],
//       },
//     };
//   });
// const mappedSubjectsByType = mappedSubjectInfo.map((item) => {
//   return {
//     idSection: item.id,
//     idHorario: item.id,
//     subjectType: item.values.tipoClase,
//     subjectInfo: [item],
//   };
// });

//   let result = {
//     id: original.idCurso,
//     idMateria: original.id,
//     section: original.title,
//     subjectData: {
//       //@ts-ignore
//       subjectsByType: mappedSubjectsByType,
//       subjectData: {
//         idA: 0,
//         idC: 0,
//         idS: 0,
//         tic: "0",
//         tim: "0",
//       },
//       reserveData: {
//         idA: 0, // Valor predeterminado
//         idS: original.idCurso, // Usando el ID del curso
//         idC: 0, // Valor predeterminado
//         tic: "NORMAL", // Valor predeterminado
//         tim: original.tipoMateria, // Usando el tipo de materia
//       },
//     },
//     tipoMateria: original.tipoMateria,
//     title: original.title,
//     obs: "", // Valor predeterminado
//     sectionNumber: Number(original.sections[0].sectionNumber),
//     year: original.sections[0].year,
//     catalogo: original.sections[0].catalogo,
//     link: original.sections[0].link,
//     tipoMinimo: original.sections[0].tipoMinimo,
//     //@ts-ignore
//     creditos: parseFloat(original.sections[0].creditos) || 0, // Convertir a número
//     tag: original.sections[0].tag,
//     correlativa: false, // Valor predeterminado
//     modalidad: "", // Valor predeterminado
//   };

//   //@ts-ignore
//   return result
// }
